import { useEffect, useState } from "react";
import { executeRequest, getToken } from "../../functions/requests";
import { usePageState } from "../../Layout";
import List from "../../components/List";

export default function Users() {
  const { pageState, setPageState } = usePageState();
  const [userData, setUserData] = useState(pageState.userData || null);
  const [users, setUsers] = useState([]);
  const [usersLoading, setUsersLoading] = useState(true);

  const getUsers = async () => {
    console.log(getToken());
    await executeRequest(
      "https://portal.edge-solutions.online/api/users",
      "GET",
      null,
      {
        Authorization: "Bearer " + getToken(),
      },
      async (data) => {
        console.log(data);
        setUsers(data);
      },
      (error) => {
        console.log(error);
      }
    );
    setUsersLoading(false);
  };

  useEffect(() => {
    console.log(userData);
    getUsers();
  }, []);

  return (
    <div>
      <h1 className="page-title">Users</h1>
      <List
        headers={
          userData.user_type === "customer"
            ? [
                "First name",
                "Last name",
                "Email",
                "Phone",
                "Applications",
                "Created at",
              ]
            : [
                "First name",
                "Last name",
                "Email",
                "Phone",
                "Role",
                "Created at",
              ]
        }
        items={users}
        isLoading={usersLoading}
      />
    </div>
  );
}
