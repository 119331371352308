import { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { deleteApplicationToken, deleteToken } from "../functions/requests";
import Button from "./Button";
import IconLight from "../icons/IconLight";
import ButtonSettings from "./ButtonSettings";
import IconPerson from "./IconPerson";
import IconArrowLeft from "./IconArrowLeft";

export default function LayoutSidebar({ userData }) {
  const location = useLocation();
  const navigate = useNavigate();
  const [currentTheme, setCurrentTheme] = useState(
    localStorage.getItem("project_management_platform_theme") || "light"
  );
  const [sidebarStatus, setSidebarStatus] = useState(
    localStorage.getItem("project_management_platform_sidebar_status") || "open"
  );
  const [links] = useState(() => {
    // Common project links for all user types
    const projectLinks = [
      { href: "/projects/real-estate-agenda", label: "Real Estate Agenda" },
      { href: "/projects/booking", label: "Booking" },
      { href: "/projects/online-menu", label: "Online Menu" },
      { href: "/projects/loyalty-program", label: "Loyalty Program" },
      { href: "/projects/tickets", label: "Tickets" },
      { href: "/projects/eshops", label: "Eshops" },
      { href: "/projects/websites", label: "Websites" },
    ];

    // Define links based on user type
    if (userData.user_type === "user") {
      return [
        { href: "/", label: "Dashboard" },
        userData.role.slug === "administrator" && {
          href: "/web-scraper",
          label: "Web Scraper",
        },
        userData.role.slug === "administrator" && {
          href: "/calculator",
          label: "Calculator",
        },
        (userData.role.slug === "administrator" ||
          userData.role.slug === "social_media_manager") && {
          href: "/social-media-management",
          label: "Social Media Management",
        },
        { href: "/support-tickets", label: "Support Tickets" },
        { href: "/projects", label: "Projects", children: projectLinks },
        userData.role.slug === "administrator" && {
          href: "/users",
          label: "Users",
        },
        { href: "/documentation", label: "Documentation" },
      ].filter(Boolean); // Filter out any false items due to conditions
    }

    if (userData.user_type === "customer") {
      return [
        { href: "/support-tickets", label: "Support Tickets" },
        { href: "/projects", label: "Projects", children: projectLinks },
        { href: "/users", label: "Users" },
        { href: "/documentation", label: "Documentation" },
      ];
    }

    if (userData.user_type === "customer_user") {
      return [
        { href: "/projects", label: "Projects", children: projectLinks },
        { href: "/documentation", label: "Documentation" },
      ];
    }

    return [];
  });

  const logout = () => {
    deleteToken(userData.uuid);
    userData.applications.forEach((application) => {
      deleteApplicationToken(application.slug);
      console.log(application.slug);
    });
    window.location.href = "/login";
  };

  const changeTheme = () => {
    const newTheme = currentTheme === "light" ? "dark" : "light";
    setCurrentTheme(newTheme); // Update the theme state
    if (newTheme === "dark") {
      document.getElementById("root").classList.add("theme-dark");
    } else {
      document.getElementById("root").classList.remove("theme-dark");
    }
    // Store the new theme in localStorage after state update
    localStorage.setItem("project_management_platform_theme", newTheme);
  };

  const toggleSidebar = () => {
    const newSidebarStatus = sidebarStatus === "open" ? "closed" : "open";
    setSidebarStatus(newSidebarStatus); // Update the sidebar state
    if (newSidebarStatus === "closed") {
      document.getElementById("root").classList.add("sidebar-is-closed");
    } else {
      document.getElementById("root").classList.remove("sidebar-is-closed");
    }
    // Store the new theme in localStorage after state update
    localStorage.setItem(
      "project_management_platform_sidebar_status",
      newSidebarStatus
    );
  };

  const setSidebarWidth = () => {
    const aside = document.querySelector("aside");
    const sidebarSwitch = document.getElementById("sidebar-switch");
    const content = document.getElementById("content");
    if (aside) {
      const sidebarWidth = aside.offsetWidth;
      aside.style.setProperty("--sidebarWidth", `${sidebarWidth}px`);
      sidebarSwitch.style.setProperty("--sidebarWidth", `${sidebarWidth}px`);
      content.style.setProperty("--sidebarWidth", `${sidebarWidth}px`);
    }
  };

  // Apply the theme and sidebar status on load
  useEffect(() => {
    if (currentTheme === "dark") {
      document.getElementById("root").classList.add("theme-dark");
    } else {
      document.getElementById("root").classList.remove("theme-dark");
    }

    if (sidebarStatus === "closed") {
      document.getElementById("root").classList.add("sidebar-is-closed");
    } else {
      document.getElementById("root").classList.remove("sidebar-is-closed");
    }

    setSidebarWidth();
    window.addEventListener("resize", setSidebarWidth);
    return () => {
      window.removeEventListener("resize", setSidebarWidth);
    };
  }, [currentTheme, sidebarStatus]);

  return (
    <>
      <aside className="padding-s flex flex-direction-column flex-gap-m">
        <h2>Project Dash</h2>
        <nav className="flex flex-gap-s flex-direction-column">
          {links.map((link, index) => {
            const isActive =
              link.href === "/"
                ? location.pathname === "/"
                : location.pathname.includes(link.href);
            return (
              <div className="sidebar-link">
                <Link
                  key={index}
                  to={link.href}
                  className={`${isActive ? "active" : ""}`}
                >
                  {link.label}
                </Link>
                {link.children && (
                  <div
                    className={`sidebar-link-children ${
                      isActive ? "active" : ""
                    }`}
                  >
                    {link.children.map((child, index) => {
                      const isChildActive =
                        child.href === "/"
                          ? location.pathname === "/"
                          : location.pathname.includes(child.href);
                      return (
                        <Link
                          key={"child_" + index}
                          to={child.href}
                          className={`${isChildActive ? "active" : ""}`}
                        >
                          {index + 1 + ". " + child.label}
                        </Link>
                      );
                    })}
                  </div>
                )}
              </div>
            );
          })}
        </nav>
        <div className="settings flex flex-gap-xs margin-top-auto">
          <ButtonSettings
            icon={<IconLight color="black" />}
            onClicked={() => changeTheme()}
          />
          <ButtonSettings
            icon={<IconPerson color="black" />}
            onClicked={() => navigate("/profile")}
          />
        </div>
        <div className="user-data">
          <div>Logged in as:</div>
          <div>
            {userData.first_name} {userData.last_name}
          </div>
          <div>({userData.email})</div>
        </div>
        <a href="#" onClick={() => logout()}>
          Logout
        </a>
      </aside>
      <div
        id="sidebar-switch"
        className="sidebar-switch flex flex-align-center flex-justify-center"
        onClick={() => toggleSidebar()}
      >
        <IconArrowLeft />
      </div>
    </>
  );
}
