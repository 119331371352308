import { useEffect, useState } from "react";
import Spacer from "../components/Spacer";
import IconLoadingWave from "../icons/IconLoadingWave";
import Button from "../components/Button";

export default function Dashboard() {
  const [liveApplicationsInfo, setLiveApplicationsInfo] = useState(null);
  const [liveApplicationsInfoLoading, setLiveApplicationsInfoLoading] =
    useState(true);
  const [totalCustomers, setTotalCustomers] = useState(null);
  const [totalCustomersLoading, setTotalCustomersLoading] = useState(true);
  const [totalUsers, setTotalUsers] = useState(null);
  const [totalUsersLoading, setTotalUsersLoading] = useState(true);

  const getLiveApplicationsInfo = async () => {
    setLiveApplicationsInfoLoading(true);
    // setLiveApplicationsInfoLoading(false);
  };

  const getTotalCustomers = async () => {
    setTotalCustomersLoading(true);
    // setTotalCustomersLoading(false);
  };

  const getTotalUsers = async () => {
    setTotalUsersLoading(true);
    // setTotalUsersLoading(false);
  };

  const loadInfo = async () => {
    await getLiveApplicationsInfo();
    await getTotalCustomers();
    await getTotalUsers();
  };

  useEffect(() => {
    loadInfo();
  }, []);

  return (
    <div>
      <h1 className="page-title">Dashboard</h1>
      <Button
        text="Reload data"
        loading={
          liveApplicationsInfoLoading ||
          totalCustomersLoading ||
          totalUsersLoading
        }
        onClicked={() => loadInfo()}
      />
      <Spacer />
      <h3>Live projects:</h3>
      {liveApplicationsInfoLoading && <IconLoadingWave />}

      <Spacer />
      <h3>Total customers:</h3>
      {totalCustomersLoading && <IconLoadingWave />}

      <Spacer />
      <h3>Total users:</h3>
      {totalUsersLoading && <IconLoadingWave />}
    </div>
  );
}
