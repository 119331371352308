import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

export default function RedirectTo({ link }) {
  const navigate = useNavigate();
  useEffect(() => {
    navigate(link);
  }, []);
  return null;
}
