import { useEffect, useState } from "react";
import IconLoadingWave from "../../../icons/IconLoadingWave";
import List from "../../../components/List";
import {
  apiOnlineMenuUrl,
  executeRequest,
  getApplicationToken,
} from "../../../functions/requests";
import { useNavigate } from "react-router-dom";
import Spacer from "../../../components/Spacer";
import Button from "../../../components/Button";

export default function OnlineMenuSettings() {
  const navigate = useNavigate();
  const [menuSettings, setMenuSettings] = useState([]);
  const [loading, setLoading] = useState(true);

  const getMenuSettings = async () => {
    await executeRequest(
      `${apiOnlineMenuUrl}menu-settings`,
      "GET",
      null,
      {
        Authorization: "Bearer " + getApplicationToken("online_menu"),
      },
      async (data) => {
        console.log(data);
        setMenuSettings(data);
        setLoading(false);
      },
      (error) => {
        console.log(error);
      }
    );
  };

  useEffect(() => {
    getMenuSettings();
  }, []);

  return (
    <>
      <h2>My menu setups</h2>
      <Spacer />
      <Button
        text="< Back"
        type="underlined"
        onClicked={() => {
          navigate("/projects/online-menu");
        }}
      />
      <Spacer />
      {loading ? (
        <IconLoadingWave />
      ) : menuSettings.length < 1 ? (
        <div>No menu setups created yet.</div>
      ) : (
        <List
          headers={[
            "Title",
            "Logo",
            "Main image",
            "Contact info",
            "Social links",
            "Main language",
          ]}
          itemType="menu-settings"
          items={menuSettings}
          isLoading={loading}
          onView={(item) => {
            navigate("/projects/online-menu/menu-settings/" + item.uuid);
          }}
        />
      )}
    </>
  );
}
