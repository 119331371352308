import { useEffect, useState } from "react";
import { usePageState } from "../../Layout";
import FormInput from "../../components/FormInput";
import Spacer from "../../components/Spacer";

export default function Calculator() {
  const { pageState, setPageState } = usePageState();
  const [totalProjectPrice, setTotalProjectPrice] = useState(
    pageState.calculatorTotalProjectPrice || 0
  );
  const [priceOfEachDose, setPriceOfEachDose] = useState(
    pageState.calculatorPriceOfEachDose || 0
  );
  const [numberOfDays, setNumberOfDays] = useState(
    pageState.calculatorNumberOfDays || 0
  );

  const [calculations, setCalculations] = useState(
    pageState.calculatorCalculations || 0
  );

  const calculate = () => {
    let profitPerDay = totalProjectPrice / numberOfDays;
    let amountOfDoses = Math.ceil(totalProjectPrice / priceOfEachDose);
    let priceOfLastDose =
      totalProjectPrice - priceOfEachDose * (amountOfDoses - 1);
    let dosePerDays = Math.ceil(numberOfDays / amountOfDoses);
    let result = {
      profitPerDay: profitPerDay,
      amountOfDoses: amountOfDoses,
      priceOfLastDose: priceOfLastDose,
      dosePerDays: dosePerDays,
    };
    setCalculations(result);
  };

  // Sync the pageState whenever any state value changes
  useEffect(() => {
    calculate();
    setPageState((prevState) => ({
      ...prevState,
      calculatorTotalProjectPrice: totalProjectPrice,
      calculatorPriceOfEachDose: priceOfEachDose,
      calculatorNumberOfDays: numberOfDays,
      calculatorCalculations: calculations,
    }));
  }, [
    totalProjectPrice,
    priceOfEachDose,
    numberOfDays,
    calculations,
    setPageState,
  ]);

  return (
    <div>
      <h1 className="page-title">Calculator</h1>
      <h3>Enter project's total price:</h3>
      <FormInput
        type="number"
        min="0"
        step="0.01"
        value={totalProjectPrice}
        onChanged={(value) => setTotalProjectPrice(value)}
      />
      <Spacer />
      <h3>Amount of each dose:</h3>
      <FormInput
        type="number"
        min="0"
        step="0.01"
        value={priceOfEachDose}
        onChanged={(value) => setPriceOfEachDose(value)}
      />
      <Spacer />
      <h3>Project duration in days:</h3>
      <FormInput
        type="number"
        min="0"
        step="1"
        value={numberOfDays}
        onChanged={(value) => setNumberOfDays(value)}
      />
      <Spacer />
      {totalProjectPrice > 0 && priceOfEachDose > 0 && numberOfDays > 0 ? (
        <>
          <h3>Profit per day: {calculations.profitPerDay}</h3>
          <h3>
            Amount of doses: {calculations.amountOfDoses}{" "}
            {calculations.priceOfLastDose !== priceOfEachDose
              ? `(last at 
            ${calculations.priceOfLastDose})`
              : `(exactly)`}
          </h3>
          <h3>Doses must be paid per days: {calculations.dosePerDays}</h3>
        </>
      ) : (
        <h3>Calculations will show here after completing all above fields.</h3>
      )}
    </div>
  );
}
