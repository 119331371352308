import Button from "./Button";
import FormInput from "./FormInput";

export default function Form({
  inputs,
  submitButtonText,
  onSubmit,
  loading,
  disabled,
}) {
  return (
    <div className="form">
      {inputs.map((input, index) => {
        return (
          <FormInput
            key={index}
            type={input.type}
            selectOptions={input.selectOptions}
            title={input.title}
            placeholder={input.placeholder}
            value={input.value}
            hasObscureText={input.hasObscureText}
            obscureText={input.obscureText}
            onChanged={input.onChanged}
          />
        );
      })}
      {onSubmit && (
        <Button
          disabled={disabled}
          loading={loading}
          onClicked={onSubmit}
          text={submitButtonText}
        />
      )}
    </div>
  );
}
