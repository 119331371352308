import { useEffect, useState } from "react";
import Button from "../../../components/Button";
import { useNavigate, useParams } from "react-router-dom";
import {
  apiOnlineMenuUrl,
  executeRequest,
  getApplicationToken,
} from "../../../functions/requests";
import IconLoadingWave from "../../../icons/IconLoadingWave";
import List from "../../../components/List";
import Spacer from "../../../components/Spacer";

export default function OnlineMenuOrders({
  showInProgress = false,
  showAccepted = false,
  showRejected = false,
}) {
  const { uuid } = useParams();
  const navigate = useNavigate();
  const [orders, setOrders] = useState([]);
  const [loading, setLoading] = useState(true);

  const getInProgressOrders = async () => {
    await executeRequest(
      `${apiOnlineMenuUrl}orders/${uuid}/pending`,
      "GET",
      null,
      {
        Authorization: "Bearer " + getApplicationToken("online_menu"),
      },
      async (data) => {
        console.log(data);
        setOrders(data.data);
        setLoading(false);
      },
      (error) => {
        console.log(error);
      }
    );
  };

  const getAcceptedOrders = async () => {
    setLoading(true);
    await executeRequest(
      `${apiOnlineMenuUrl}orders/${uuid}/accepted`,
      "GET",
      null,
      {
        Authorization: "Bearer " + getApplicationToken("online_menu"),
      },
      async (data) => {
        console.log(data);
        setOrders(data.data);
        setLoading(false);
      },
      (error) => {
        console.log(error);
      }
    );
  };

  const getRejectedOrders = async () => {
    setLoading(true);
    await executeRequest(
      `${apiOnlineMenuUrl}orders/${uuid}/rejected`,
      "GET",
      null,
      {
        Authorization: "Bearer " + getApplicationToken("online_menu"),
      },
      async (data) => {
        console.log(data);
        setOrders(data.data);
        setLoading(false);
      },
      (error) => {
        console.log(error);
      }
    );
  };

  const getOrdersConditionally = async () => {
    if (showInProgress) {
      getInProgressOrders();
    }
    if (showAccepted) {
      getAcceptedOrders();
    }
    if (showRejected) {
      getRejectedOrders();
    }
  };

  const acceptOrder = async (order) => {
    await executeRequest(
      `${apiOnlineMenuUrl}orders/${order.uuid}`,
      "PUT",
      {
        accepted_at: Math.floor(Date.now() / 1000),
      },
      {
        Authorization: "Bearer " + getApplicationToken("online_menu"),
      },
      async (data) => {
        console.log(data);
      },
      (error) => {
        console.log(error);
      }
    );
  };

  const rejectOrder = async (order) => {
    await executeRequest(
      `${apiOnlineMenuUrl}orders/${order.uuid}`,
      "PUT",
      {
        declined_at: Math.floor(Date.now() / 1000),
      },
      {
        Authorization: "Bearer " + getApplicationToken("online_menu"),
      },
      async () => {
        getOrdersConditionally();
      },
      (error) => {
        console.log(error);
      }
    );
  };

  useEffect(() => {
    let intervalId;

    // Initial load of tickets from loaded parameter
    if (showInProgress) {
      setLoading(true);
      getInProgressOrders();
      // Set up interval to fetch in-progress orders every 10 seconds
      intervalId = setInterval(() => {
        getInProgressOrders();
      }, 10000);
    } else if (showAccepted) {
      getAcceptedOrders();
    } else if (showRejected) {
      getRejectedOrders();
    }

    // Cleanup function to clear the interval
    return () => {
      if (intervalId) {
        clearInterval(intervalId);
      }
    };
  }, [showInProgress, showAccepted, showRejected]);

  return (
    <>
      <Button
        type="underlined"
        text="< Back"
        onClicked={() =>
          navigate("/projects/online-menu/menu-settings/" + uuid)
        }
      />
      <Spacer />
      <div className="flex flex-gap-s">
        <Button
          type="empty"
          active={showInProgress}
          text="Pending"
          onClicked={() => {
            setOrders([]);
            navigate("/projects/online-menu/menu-settings/" + uuid + "/orders");
          }}
        />
        <Button
          type="empty"
          active={showAccepted}
          text="Accepted"
          onClicked={() => {
            setOrders([]);
            navigate(
              "/projects/online-menu/menu-settings/" + uuid + "/orders/accepted"
            );
          }}
        />
        <Button
          type="empty"
          active={showRejected}
          text="Rejected"
          onClicked={() => {
            setOrders([]);
            navigate(
              "/projects/online-menu/menu-settings/" + uuid + "/orders/rejected"
            );
          }}
        />
      </div>
      <Spacer />
      {loading ? (
        <IconLoadingWave />
      ) : orders.length < 1 ? (
        <div>No orders yet.</div>
      ) : (
        <List
          headers={["Table number", "Items", "Created at"]}
          itemType="menu-order"
          items={orders}
          isLoading={loading}
          onAccept={(item) => {
            acceptOrder(item);
          }}
          onReject={(item) => {
            rejectOrder(item);
          }}
        />
      )}
    </>
  );
}
