import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  apiOnlineMenuUrl,
  executeRequest,
  getApplicationToken,
} from "../../../functions/requests";
import IconLoadingWave from "../../../icons/IconLoadingWave";
import List from "../../../components/List";
import Button from "../../../components/Button";
import Spacer from "../../../components/Spacer";
import FormInput from "../../../components/FormInput";

export default function OnlineMenuSetting() {
  const { uuid } = useParams();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [menuSetting, setMenuSetting] = useState(null);
  const [selectedLanguageCode, setSelectedLanguageCode] = useState(null);
  const [menuToEdit, setMenuToEdit] = useState(null);
  const [menuCategoryToEdit, setMenuCategoryToEdit] = useState(null);
  const [menuItemToEdit, setMenuItemToEdit] = useState(null);
  const [menuItemAttributeToEdit, setMenuItemAttributeToEdit] = useState(null);

  const getMenuSetting = async () => {
    await executeRequest(
      `${apiOnlineMenuUrl}menu-settings/${uuid}`,
      "GET",
      null,
      {
        Authorization: "Bearer " + getApplicationToken("online_menu"),
      },
      async (data) => {
        console.log(data);
        setMenuSetting(data);
        setSelectedLanguageCode(data.main_language.code);
        setLoading(false);
      },
      (error) => {
        console.log(error);
      }
    );
  };

  useEffect(() => {
    getMenuSetting();
  }, []);

  return menuToEdit === null ? (
    <>
      <h2></h2>
      {loading ? (
        <IconLoadingWave />
      ) : menuSetting === null ? (
        <div>No menu setup found.</div>
      ) : (
        <>
          <Button
            text="< Back"
            type="underlined"
            onClicked={() => {
              navigate("/projects/online-menu/menu-settings");
            }}
          />
          <Spacer />
          <List
            showActionsHeader={false}
            headers={[
              "Title",
              "Logo",
              "Main image",
              "Contact info",
              "Social links",
              "Main language",
            ]}
            itemType="menu-settings"
            items={[menuSetting]}
            isLoading={loading}
          />
          <Spacer />
          <div className="flex flex-gap-s">
            {menuSetting.languages.length < 1 ? (
              <div>No menus added in any language yet.</div>
            ) : (
              menuSetting.languages.map((language, index) => {
                return (
                  <Button
                    key={index}
                    text={language.code}
                    onClicked={() => {
                      setSelectedLanguageCode(language.code);
                    }}
                    active={language.code === selectedLanguageCode}
                  />
                );
              })
            )}
          </div>
          <Spacer />
          {/* Menus list */}
          <List
            headers={["Slogan", "Description", "Currency", "Categories"]}
            itemType="menu"
            items={menuSetting.menus.filter(
              (menu) => menu.language.code === selectedLanguageCode
            )}
            isLoading={loading}
            onEdit={(item) => {
              setMenuToEdit(item);
            }}
          />
          <Spacer />
        </>
      )}
    </>
  ) : (
    <>
      <Button
        text="< Back"
        type="underlined"
        onClicked={() => {
          setMenuToEdit(null);
        }}
      />
      <Spacer />
      <div className="flex flex-gap-m flex-direction-column">
        <h2>
          {menuSetting.title} ({menuToEdit.language.code})
        </h2>
        <FormInput
          type="text"
          value={menuToEdit.slogan}
          title="Slogan"
          onChanged={(value) => {
            setMenuToEdit({
              ...menuToEdit,
              slogan: value,
            });
          }}
        />
        <FormInput
          type="textarea"
          value={menuToEdit.description}
          title="Description"
          onChanged={(value) => {
            setMenuToEdit({
              ...menuToEdit,
              description: value,
            });
          }}
        />
        <FormInput
          type="select"
          selectOptions={menuSetting.languages}
          value={menuToEdit.language.uuid}
          title="Language"
          onChanged={(value) => {
            setMenuToEdit({
              ...menuToEdit,
              language: value,
            });
          }}
        />
        <FormInput
          type="select"
          selectOptions={[menuToEdit.currency]}
          value={menuToEdit.currency.uuid}
          title="Currency"
          onChanged={(value) => {
            setMenuToEdit({
              ...menuToEdit,
              currency: value,
            });
          }}
        />
        <h2>Categories</h2>
        {menuToEdit.categories.map((category, index) => {
          return (
            <div key={index} className="flex flex-direction-column flex-gap-s">
              {menuCategoryToEdit !== null &&
              menuCategoryToEdit.uuid === category.uuid ? (
                <>
                  <FormInput
                    type="text"
                    value={menuCategoryToEdit.name}
                    title="Name"
                    onChanged={(value) => {
                      setMenuCategoryToEdit({
                        ...menuCategoryToEdit,
                        name: value,
                      });
                    }}
                  />
                  <FormInput
                    type="textarea"
                    value={menuCategoryToEdit.description}
                    title="Description"
                    onChanged={(value) => {
                      setMenuCategoryToEdit({
                        ...menuCategoryToEdit,
                        description: value,
                      });
                    }}
                  />
                </>
              ) : (
                <>
                  <h3>{category.name}</h3>
                  <div>Description: {category.description}</div>
                </>
              )}
              <div className="padding-top-xs">
                <u>Items ({category.items.length})</u>
              </div>
              {category.items.map((item, itemIndex) => {
                return (
                  <div
                    key={itemIndex}
                    className="flex flex-direction-column flex-gap-s padding-left-s padding-bottom-s padding-top-s"
                  >
                    {menuItemToEdit !== null &&
                    menuItemToEdit.uuid === item.uuid ? (
                      <>
                        <FormInput
                          type="text"
                          value={menuItemToEdit.name}
                          title="Name"
                          onChanged={(value) => {
                            setMenuItemToEdit({
                              ...menuItemToEdit,
                              name: value,
                            });
                          }}
                        />
                        <FormInput
                          type="text"
                          value={menuItemToEdit.description}
                          title="Description"
                          onChanged={(value) => {
                            setMenuItemToEdit({
                              ...menuItemToEdit,
                              description: value,
                            });
                          }}
                        />
                        <FormInput
                          type="number"
                          min="0.00"
                          step="0.01"
                          value={menuItemToEdit.description}
                          title="Price"
                          onChanged={(value) => {
                            setMenuItemToEdit({
                              ...menuItemToEdit,
                              price: value,
                            });
                          }}
                        />
                        <FormInput
                          type="number"
                          min="0.00"
                          step="0.01"
                          value={menuItemToEdit.description}
                          title="Sale price"
                          onChanged={(value) => {
                            setMenuItemToEdit({
                              ...menuItemToEdit,
                              sale_price: value,
                            });
                          }}
                        />
                      </>
                    ) : (
                      <>
                        <h3>{item.name}</h3>
                        <div>Description: {item.description}</div>
                        <div>
                          Price: {item.price ? item.price : "-"}{" "}
                          {item.price && menuToEdit.currency.symbol}
                        </div>
                        <div>
                          Sale price: {item.sale_price ? item.sale_price : "-"}{" "}
                          {item.sale_price && menuToEdit.currency.symbol}
                        </div>
                      </>
                    )}
                  </div>
                );
              })}
              <Spacer height="10px" />
            </div>
          );
        })}
      </div>
    </>
  );
}
