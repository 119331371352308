export default function Documentation() {
  return (
    <div>
      <h1>System Architecture</h1>
      <h2>Connected Services</h2>
      <ol>
        <li>
          <span>Web Info Scraper (for admins)</span>
          <ul>
            <li>Link: webinfoscraper.edge-solutions.online</li>
          </ul>
        </li>
        <li>
          <span>Social Media Management (for admins)</span>
          <ul>
            <li>Link: sm-management.edge-solutions.online</li>
          </ul>
        </li>
        <li>
          <span>Email Service</span>
          <ul>
            <li>Link: email.edge-solutions.online</li>
          </ul>
        </li>
        <li>
          <span>Online Menu</span>
          <ul>
            <li>Link: menu.edge-solutions.online</li>
          </ul>
        </li>
        <li>
          <span>Loyalty Program</span>
          <ul>
            <li>Link: loyalty.edge-solutions.online</li>
          </ul>
        </li>
        <li>
          <span>Eshop template</span>
          <ul>
            <li>Link: eshop.edge-solutions.online</li>
          </ul>
        </li>
      </ol>
    </div>
  );
}
