import IconLoading from "../icons/IconLoading";

export default function LoaderPage() {
  return (
    <div className="loader-page padding-m flex flex-align-center flex-justify-center flex-direction-column flex-gap-m">
      <h1>Support Platform</h1>
      <h3>Loading your Data...</h3>
      <div>
        <IconLoading />
      </div>
    </div>
  );
}
