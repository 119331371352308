import "./App.css";
import { createContext, useContext, useEffect, useState } from "react";
import { apiUrl, executeRequest, getToken } from "./functions/requests";
import LayoutCopyright from "./components/LayoutCopyright";
import LayoutSidebar from "./components/LayoutSidebar";
import LoaderPage from "./components/LoaderPage";
import RedirectTo from "./components/RedirectLogin";

// Create a new context
const PageStateContext = createContext();

// Provider component to wrap your app
export const PageStateProvider = ({ children }) => {
  const [pageState, setPageState] = useState({}); // Store your state here
  return (
    <PageStateContext.Provider value={{ pageState, setPageState }}>
      {children}
    </PageStateContext.Provider>
  );
};

// Custom hook to use the context
export const usePageState = () => {
  return useContext(PageStateContext);
};

export default function Layout({ children }) {
  const { pageState, setPageState } = usePageState();
  const [loading, setLoading] = useState(true);
  const [userData, setUserData] = useState(null);

  const fetchUserData = async () => {
    setLoading(true);
    await executeRequest(
      `${apiUrl}profile`,
      "GET",
      null,
      {
        Authorization: "Bearer " + getToken(),
      },
      (response) => {
        setUserData(response);
        setPageState((prevState) => ({
          ...prevState,
          userData: response,
        }));
      },
      (error) => {
        console.log(error);
      }
    );
    setLoading(false);
  };

  useEffect(() => {
    fetchUserData();
  }, []);

  // If still loading, show the loader
  if (loading) {
    return <LoaderPage />;
  }

  // If user data couldn't be fetched (null), show login page
  if (!userData) {
    return <RedirectTo link="/login" />;
  }

  return (
    <div id="content" className="flex">
      {/* Sidebar */}
      <LayoutSidebar userData={userData} />

      {/* Main Content */}
      <main className="padding-s">
        {children}
        <LayoutCopyright />
      </main>
    </div>
  );
}
