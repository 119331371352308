import { useState } from "react";
import IconClose from "../icons/IconClose";
import IconEdit from "../icons/IconEdit";
import IconEyeOpen from "../icons/IconEyeOpen";
import IconLoadingWave from "../icons/IconLoadingWave";
import { usePageState } from "../Layout";
import Button from "./Button";
import Message from "./Message";
import IconPlus from "../icons/IconPlus";
import { clientOnlineMenuUrl } from "../functions/requests";

export default function List({
  showActionsHeader = true,
  headers = [],
  items = [],
  itemType = "user",
  isLoading = true,
  onView = null,
  onEdit = null,
  onDelete = null,
  onAccept = null,
  onReject = null,
}) {
  const { pageState, setPageState } = usePageState();
  const [userData, setUserData] = useState(pageState.userData || null);

  return isLoading !== true ? (
    items.length > 0 ? (
      <>
        <div className={`list`}>
          <table>
            <thead>
              <tr>
                {showActionsHeader && <th>Actions</th>}
                {headers.length > 0 &&
                  headers.map((header, index) => {
                    return <th key={index}>{header}</th>;
                  })}
              </tr>
            </thead>
            <tbody>
              {items.map((item, index) => {
                return (
                  <tr key={index}>
                    {showActionsHeader && (
                      <td className="actions">
                        <div className="actions">
                          {onView !== null && (
                            <Button
                              icon={<IconEyeOpen color="white" />}
                              text="View"
                              onClicked={() => onView(item)}
                            />
                          )}
                          {onEdit !== null && (
                            <>
                              <Button
                                icon={<IconEdit />}
                                text="Edit"
                                onClicked={() => onEdit(item)}
                              />
                            </>
                          )}
                          {onDelete !== null && (
                            <>
                              <Button
                                icon={<IconClose />}
                                text="Delete"
                                type="danger"
                                onClicked={() => onDelete(item)}
                              />
                            </>
                          )}
                          {onAccept !== null && (
                            <>
                              <Button
                                icon={<IconPlus />}
                                text="Accept"
                                type="success"
                                onClicked={() => onAccept(item)}
                              />
                            </>
                          )}
                          {onReject !== null && (
                            <>
                              <Button
                                icon={<IconClose />}
                                text="Reject"
                                type="danger"
                                onClicked={() => onReject(item)}
                              />
                            </>
                          )}
                        </div>
                      </td>
                    )}

                    {/* Users */}
                    {itemType == "user" && (
                      <>
                        {item.first_name && <td>{item.first_name}</td>}
                        {item.last_name && <td>{item.last_name}</td>}
                        {item.email && (
                          <td>
                            <a href={`mailto:${item.email}`}>{item.email}</a>
                          </td>
                        )}
                        {item.phone && (
                          <td>
                            <a href={`tel:${item.phone}`}>{item.phone}</a>
                          </td>
                        )}
                        <td>{item.role.name}</td>
                      </>
                    )}

                    {/* Customers */}
                    {itemType == "customer" && (
                      <>
                        {item.first_name && <td>{item.first_name}</td>}
                        {item.last_name && <td>{item.last_name}</td>}
                        {item.email && (
                          <td>
                            <a href={`mailto:${item.email}`}>{item.email}</a>
                          </td>
                        )}
                        {item.phone && (
                          <td>
                            <a href={`tel:${item.phone}`}>{item.phone}</a>
                          </td>
                        )}
                        {item.vat && <td>{item.vat}</td>}
                        {item.applications &&
                          item.applications.map((application, index) => {
                            return (
                              <div key={index}>
                                <h3>{application.name}</h3>
                                {application.permissions && (
                                  <span>
                                    {application.permissions.map(
                                      (applicationPermission, index) => {
                                        return (
                                          <span
                                            key={
                                              "application_permission_" + index
                                            }
                                          >
                                            {applicationPermission.name}
                                          </span>
                                        );
                                      }
                                    )}
                                  </span>
                                )}
                              </div>
                            );
                          })}
                      </>
                    )}

                    {/* Menu settings */}
                    {itemType === "menu-settings" && (
                      <>
                        {item.title ? <td>{item.title}</td> : <td>-</td>}
                        {item.logo_url && (
                          <td>
                            <img src={clientOnlineMenuUrl + item.logo_url} />
                          </td>
                        )}
                        {item.main_image_url && (
                          <td>
                            <img
                              src={clientOnlineMenuUrl + item.main_image_url}
                            />
                          </td>
                        )}
                        <td>
                          {item.phone ? <div>{item.phone}</div> : <div>-</div>}
                          {item.email ? <div>{item.email}</div> : <div>-</div>}
                        </td>
                        <td>
                          <div>
                            Facebook:
                            {item.facebook_url ? (
                              <div>{item.facebook_url}</div>
                            ) : (
                              <div>-</div>
                            )}
                          </div>
                          <div>
                            Instagram:
                            {item.instagram_url ? (
                              <div>{item.instagram_url}</div>
                            ) : (
                              <div>-</div>
                            )}
                          </div>
                          <div>
                            TikTok:
                            {item.tiktok_url ? (
                              <div>{item.tiktok_url}</div>
                            ) : (
                              <div>-</div>
                            )}
                          </div>
                          <div>
                            Twitter:
                            {item.twitter_url ? (
                              <div>{item.twitter_url}</div>
                            ) : (
                              <div>-</div>
                            )}
                          </div>
                          <div>
                            LinkedIn:
                            {item.linkedin_url ? (
                              <div>{item.linkedin_url}</div>
                            ) : (
                              <div>-</div>
                            )}
                          </div>
                        </td>
                        {item.main_language ? (
                          <td>
                            {item.main_language.name} ({item.main_language.code}
                            )
                          </td>
                        ) : (
                          <td>-</td>
                        )}
                      </>
                    )}

                    {/* Menus */}
                    {itemType === "menu" && (
                      <>
                        {item.slogan ? <td>{item.slogan}</td> : <td>-</td>}
                        {item.description && <td>{item.description}</td>}
                        {item.currency ? (
                          <td>{item.currency.symbol}</td>
                        ) : (
                          <td>-</td>
                        )}
                        {item.categories ? (
                          <td>
                            {item.categories.map((category, index) => {
                              const itemsCount = category.items.length;
                              return (
                                <div>
                                  {category.name} ({itemsCount}{" "}
                                  {itemsCount > 1 ? "items" : "item"})
                                </div>
                              );
                            })}
                          </td>
                        ) : (
                          <td>-</td>
                        )}
                      </>
                    )}

                    {/* Menu orders */}
                    {itemType == "menu-order" && (
                      <>
                        {item.table_number ? (
                          <td>{item.table_number}</td>
                        ) : (
                          <td>-</td>
                        )}
                        {item.items ? (
                          <td>
                            {item.items.map((item, index) => {
                              return (
                                <div key={index}>
                                  <h3>{item.name}</h3>
                                  {item.attributes.length > 0 &&
                                    item.attributes.map(
                                      (attribute, attributeIndex) => {
                                        return (
                                          <div
                                            key={"attribute_" + attributeIndex}
                                          >
                                            <h3>
                                              {attribute.name}:{" "}
                                              {attribute.options[0].name}
                                            </h3>
                                          </div>
                                        );
                                      }
                                    )}
                                </div>
                              );
                            })}
                          </td>
                        ) : (
                          <td>-</td>
                        )}
                        {item.created_at ? (
                          <td>{item.created_at}</td>
                        ) : (
                          <td>-</td>
                        )}
                      </>
                    )}
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </>
    ) : (
      <Message isInline={true} message="No items found." />
    )
  ) : (
    <IconLoadingWave />
  );
}
