import { Link } from "react-router-dom";
import Spacer from "../../components/Spacer";

export default function Projects() {
  return (
    <div>
      <h1 className="page-title">Projects</h1>
      <h3>Choose a project to view details:</h3>
      <Spacer />
      <table>
        <tbody>
          <tr>
            <td>
              <Link to="/eshops">Eshops</Link>
            </td>
            <td>
              <Link to="/loyalty-program">Loyalty Program</Link>
            </td>
          </tr>
          <tr>
            <td>
              <Link to="/online-menu">Online Menu</Link>
            </td>
            <td></td>
          </tr>
        </tbody>
      </table>
    </div>
  );
}
