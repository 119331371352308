import { useEffect, useState } from "react";
import { usePageState } from "../../Layout";
import Spacer from "../../components/Spacer";

export default function Profile() {
  const { pageState, setPageState } = usePageState();

  return (
    <div>
      <h1>Profile</h1>
      <Spacer height="10px" />
      {pageState.userData && (
        <table>
          <tbody>
            <tr>
              <td>First name</td>
              <td>{pageState.userData.first_name}</td>
            </tr>
            <tr>
              <td>Last name</td>
              <td>{pageState.userData.last_name}</td>
            </tr>
            <tr>
              <td>Email</td>
              <td>{pageState.userData.email}</td>
            </tr>
            <tr>
              <td>Phone</td>
              <td>{pageState.userData.phone}</td>
            </tr>
          </tbody>
        </table>
      )}
    </div>
  );
}
