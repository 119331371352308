import { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { apiUrl } from "../../functions/requests";
import { getToken } from "../../functions/requests";
import { executeRequest } from "../../functions/requests";
import Button from "../../components/Button";
import SearchBar from "../../components/SearchBar";
import List from "../../components/List";

export default function SupportTickets({
  showAll = false,
  showCompleted = false,
  showInProgress = false,
  showCancelled = false,
}) {
  const navigate = useNavigate();
  const [tickets, setTickets] = useState([]);
  const [loading, setLoading] = useState(true);

  const getAllTickets = async () => {
    setLoading(true);
    try {
      try {
        var url = apiUrl + "tickets";
        await executeRequest(
          url,
          "GET",
          null,
          {
            Authorization: "Bearer " + getToken(),
            "Content-type": "application/json",
          },
          (data) => {
            setTickets(data);
          },
          (error) => {
            console.log(error);
          }
        );
      } catch (error) {
        console.log(error);
      }
    } finally {
      setLoading(false);
    }
  };

  const getInProgressTickets = async () => {
    setLoading(true);
    try {
      try {
        var url = apiUrl + "tickets/in-progress";
        await executeRequest(
          url,
          "GET",
          null,
          {
            Authorization: "Bearer " + getToken(),
            "Content-type": "application/json",
          },
          (data) => {
            setTickets(data);
          },
          (error) => {
            console.log(error);
          }
        );
      } catch (error) {
        console.log(error);
      }
    } finally {
      setLoading(false);
    }
  };

  const getCompletedTickets = async () => {
    setLoading(true);
    try {
      try {
        var url = apiUrl + "tickets/completed";
        await executeRequest(
          url,
          "GET",
          null,
          {
            Authorization: "Bearer " + getToken(),
            "Content-type": "application/json",
          },
          (data) => {
            setTickets(data);
          },
          (error) => {
            console.log(error);
          }
        );
      } catch (error) {
        console.log(error);
      }
    } finally {
      setLoading(false);
    }
  };

  const getCancelledTickets = async () => {
    setLoading(true);
    try {
      try {
        var url = apiUrl + "tickets/cancelled";
        await executeRequest(
          url,
          "GET",
          null,
          {
            Authorization: "Bearer " + getToken(),
          },
          (data) => {
            setTickets(data);
          },
          (error) => {
            console.log(error);
          }
        );
      } catch (error) {
        console.log(error);
      }
    } finally {
      setLoading(false);
    }
  };

  const getSearchedTickets = (query) => {
    console.log(query);
  };

  useEffect(() => {
    // Initial load of tickets from loaded parameter
    if (showCompleted) {
      getCompletedTickets();
    } else if (showInProgress) {
      getInProgressTickets();
    } else if (showCancelled) {
      getCancelledTickets();
    } else {
      getAllTickets();
    }
  }, [showAll, showInProgress, showCompleted, showCancelled]);

  return (
    <>
      <div className="flex flex-gap-s">
        <Button
          type="empty"
          active={showInProgress}
          text="In progress"
          onClicked={() => navigate("/tickets/in-progress")}
        />
        <Button
          type="empty"
          active={showCompleted}
          text="Completed"
          onClicked={() => navigate("/tickets/completed")}
        />
        <Button
          type="empty"
          active={showCancelled}
          text="Cancelled"
          onClicked={() => navigate("/tickets/cancelled")}
        />
        <SearchBar
          placeholder="Search tickets"
          onUserTyped={getSearchedTickets}
          extraClassnames="margin-left-auto"
        />
      </div>
      <List
        isLoading={loading}
        headers={[
          "Ticket Code",
          "Title",
          "Project",
          "Created By",
          "Created At",
        ]}
        items={tickets}
        onView={(ticket) => {
          console.log(ticket.code);
          navigate("/tickets/" + ticket.code);
        }}
        onRefresh={() => alert("REFRESHING")}
      />
    </>
  );
}
