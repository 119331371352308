import { useState } from "react";
import IconLoadingWave from "../icons/IconLoadingWave";

export default function SearchBar({
  placeholder = "Search...",
  onUserTyped = () => {}, // Triggered when user types something in the input
  onUserInputReset = () => {}, // Triggered when search input is empty, signals the parent component to return the initial items
  loading = false,
  isDisabled = false,
  extraClassnames = "",
}) {
  const [searchValue, setSearchValue] = useState("");

  return (
    <div
      className={`searchbar display-flex ${
        loading ? "loading" : ""
      } ${extraClassnames}`}
    >
      <input
        type="text"
        placeholder={placeholder}
        value={searchValue}
        disabled={loading || isDisabled}
        onChange={(e) => {
          let value = e.target.value;
          if (value.length > 0) {
            onUserTyped(value);
          } else {
            onUserInputReset();
          }
          setSearchValue(value);
        }}
      />
      {loading && <IconLoadingWave />}
    </div>
  );
}
