import { useState } from "react";
import Form from "../../components/Form";
import { useNavigate } from "react-router-dom";
import {
  apiUrl,
  executeRequest,
  getApplicationToken,
  saveApplicationToken,
  saveToken,
} from "../../functions/requests";
import Spacer from "../../components/Spacer";

export default function Login() {
  const navigate = useNavigate();

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState("");

  const submitForm = async () => {
    setLoading(true);
    setMessage("");
    await executeRequest(
      `${apiUrl}login`,
      "POST",
      {
        email: email,
        password: password,
      },
      {},
      async (data) => {
        console.log(data);
        saveToken(data.token);
        // Save tokens of other applications
        await data.application_tokens.map((application) => {
          saveApplicationToken(application.slug, application.token);
        });
        window.location.href = "/";
      },
      (error) => {
        setMessage(
          "Try entering correct credentials and check if connected to the internet."
        );
        setLoading(false);
      }
    );
  };

  return (
    <div className="position-fixed fixed-centered login">
      <h1>Log In</h1>
      <Spacer height="10px" />
      <h3>Project Management Platform</h3>
      <Spacer height="20px" />
      <Form
        inputs={[
          {
            type: "email",
            title: "Email",
            value: email,
            onChanged: (value) => setEmail(value),
          },
          {
            type: "text",
            title: "Password",
            hasObscureText: true,
            obsureText: true,
            value: password,
            onChanged: (value) => setPassword(value),
          },
        ]}
        loading={loading}
        disabled={email === "" || password === "" || password.length < 8}
        submitButtonText="Login"
        onSubmit={() => submitForm()}
      />
    </div>
  );
}
