import { useEffect, useState } from "react";
import { usePageState } from "../../Layout";
import Spacer from "../../components/Spacer";
import Button from "../../components/Button";

export default function WebScraper() {
  const { pageState, setPageState } = usePageState();
  const [urls, setUrls] = useState(pageState.webScraperUrls || "");
  const [results, setResults] = useState(pageState.webScraperResults || null); // To store the results from the API
  const [loading, setLoading] = useState(false); // To manage loading state

  const scrape = async () => {
    setLoading(true); // Set loading to true before the request
    setResults(null); // Reset the results
    const urlArray = urls
      .split("\n")
      .map((url) => url.trim())
      .filter(Boolean); // Split by new line, trim, and remove empty lines
    console.log(urlArray);
    try {
      const response = await fetch(
        "https://webinfoscraper.edge-solutions.online/api/scrape",
        {
          method: "POST",
          headers: {
            Authorization: "Bearer 2|1BReuyrxxKDjYyYIWduiwCqrIAMX1tRDr7Ie9mGl",
            "Content-Type": "application/json",
            Accept: "application/json",
          },
          body: JSON.stringify({ urls: urlArray }), // Send the URLs as JSON
        }
      );

      if (!response.ok) {
        throw new Error("Network response was not ok");
      }

      const data = await response.json();
      setResults(data); // Set the results from the API
    } catch (error) {
      console.error("Error:", error);
      // Handle errors (you might want to show an error message to the user)
    } finally {
      setLoading(false); // Set loading to false after the request completes
    }
  };

  useEffect(() => {
    // Update the pageState whenever URLs or results change
    setPageState((prevState) => ({
      ...prevState,
      webScraperUrls: urls,
      webScraperResults: results,
    }));
  }, [urls, results, setPageState]);

  return (
    <>
      <h1 className="page-title">Web Scraper</h1>
      <p>
        Write one URL per line. Then, press Enter to write the next one etc.
      </p>
      <p>
        In the results, when a URL is NOT found, it will be marked with{" "}
        <span className="warning">orange</span>.
      </p>
      <textarea value={urls} onChange={(e) => setUrls(e.target.value)}>
        {urls}
      </textarea>
      <Button
        disabled={urls == ""}
        loading={loading}
        onClicked={() => scrape()}
        text="Scrape info"
      />
      {results && (
        <div>
          <Spacer />
          <h2>Results:</h2>
          <Spacer />
          <table>
            <thead>
              <tr>
                <th>Name</th>
                <th>URL</th>
                <th>Emails</th>
                <th>Phones</th>
                <th>Instagram</th>
                <th>Facebook</th>
              </tr>
            </thead>
            <tbody>
              {results.map((result, index) => {
                return (
                  <tr
                    key={index}
                    className={result.url_found !== true ? "warning" : ""}
                  >
                    <td>{result.name ? result.name : "-"}</td>
                    <td>
                      <a key={index} href={result.url} target="_blank">
                        {result.url}
                      </a>
                    </td>
                    <td>
                      {result.emails
                        ? result.emails.split(", ").map((item, index) => {
                            return (
                              <a key={index} href={`mailto:${item}`}>
                                {item}
                              </a>
                            );
                          })
                        : "-"}
                    </td>
                    <td>
                      {result.phones
                        ? result.phones.split(", ").map((item, index) => {
                            return (
                              <a key={index} href={`tel:${item}`}>
                                {item}
                              </a>
                            );
                          })
                        : "-"}
                    </td>
                    <td>
                      {result.instagram
                        ? result.instagram.split(", ").map((item, index) => {
                            return (
                              <a key={index} href={item} target="_blank">
                                {item}
                              </a>
                            );
                          })
                        : "-"}
                    </td>
                    <td>
                      {result.facebook
                        ? result.facebook.split(", ").map((item, index) => {
                            return (
                              <a key={index} href={item} target="_blank">
                                {item}
                              </a>
                            );
                          })
                        : "-"}
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      )}
    </>
  );
}
