import { useEffect, useState } from "react";
import IconEyeOpen from "../icons/IconEyeOpen";
import IconEyeShut from "../icons/IconEyeShut";
import Spacer from "./Spacer";

export default function FormInput({
  type,
  min,
  max,
  step,
  selectOptions = [], // In case it is select box
  title = "",
  placeholder = "",
  value = "",
  selectedImageUrl = null, // If input type is image and has URL
  hasObscureText = false, // If set to true, a hide or show icon is shown
  obscureText = true, // Start always with hidden text
  onChanged,
}) {
  const [localObscureText, setLocalObscureText] = useState(true);
  const [selectedOptions, setSelectedOptions] = useState(value || []);
  const [imagePreview, setImagePreview] = useState(null);

  const handleMultiselectChange = (e, optionValue) => {
    const updatedSelection = e.target.checked
      ? [...selectedOptions, optionValue]
      : selectedOptions.filter((val) => val !== optionValue);

    setSelectedOptions(updatedSelection);
    onChanged(updatedSelection);
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file && (file.type === "image/jpeg" || file.type === "image/png")) {
      onChanged(file);
      setImagePreview(URL.createObjectURL(file)); // Set the preview URL
    }
  };

  useEffect(() => {
    setLocalObscureText(obscureText);
    if (selectedImageUrl && imagePreview === null) {
      setImagePreview(selectedImageUrl);
    }
  }, [obscureText]);

  return (
    <div className="input">
      {(type === "number" || type === "text" || type === "email") && (
        <>
          <input
            type={
              hasObscureText == true && localObscureText === true
                ? "password"
                : type
            }
            value={value}
            min={min}
            max={max}
            step={step}
            onChange={(e) => {
              var value = e.target.value;
              if (min) {
                if (value < min) {
                  return;
                }
              }
              if (max) {
                if (value > max) {
                  return;
                }
              }
              onChanged(value);
            }}
            placeholder={title !== "" ? " " : placeholder}
          />
          {title && <span className="input-title">{title}</span>}
          {hasObscureText === true && (
            <span
              className="input-icon"
              onClick={() => setLocalObscureText(!localObscureText)}
            >
              {localObscureText == true ? <IconEyeOpen /> : <IconEyeShut />}
            </span>
          )}
        </>
      )}

      {type === "textarea" && (
        <>
          <textarea
            value={value}
            onChange={(e) => {
              onChanged(e.target.value);
            }}
            placeholder={title !== "" ? " " : placeholder}
          >
            {value}
          </textarea>
          {title && <span className="input-title">{title}</span>}
        </>
      )}

      {type === "select" && (
        <div>
          {title && (
            <span className="input-title select-input-title">{title}</span>
          )}
          <Spacer height="10px" />
          <select defaultValue={value}>
            {selectOptions.length > 0 &&
              selectOptions.map((selectOption, index) => {
                return (
                  <option
                    key={index}
                    value={selectOption.uuid}
                    selected={selectOption.selected}
                  >
                    {selectOption.title}
                    {selectOption.name}
                    {selectOption.symbol}
                  </option>
                );
              })}
          </select>
        </div>
      )}

      {type === "checkbox" && (
        <label className="checkbox-label">
          <input
            type="checkbox"
            checked={value}
            onChange={(e) => onChanged(e.target.checked)}
          />
          {title && <span className="checkbox-title">{title}</span>}
        </label>
      )}

      {type === "multiselect" && (
        <div className="multiselect">
          {title && <span className="checkbox-title">{title}</span>}
          {selectOptions.length > 0 &&
            selectOptions.map((selectOption, index) => {
              <label key={index} className="checkbox-label">
                <input
                  type="checkbox"
                  checked={selectOption.value}
                  onChange={(e) => onChanged(e.target.checked)}
                />
                {selectOption.title && (
                  <span className="checkbox-title">{selectOption.title}</span>
                )}
              </label>;
            })}
        </div>
      )}

      {type === "image" && (
        <label className="image-label">
          {title && <span className="image-title">{title}</span>}
          <input
            type="file"
            accept="image/jpeg, image/png, image/jpg"
            onChange={handleFileChange}
          />
          {imagePreview && (
            <img
              src={imagePreview}
              alt="Image Preview"
              className="image-preview"
              style={{ width: "100px", height: "100px", marginTop: "10px" }}
            />
          )}
        </label>
      )}
    </div>
  );
}
