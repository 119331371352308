export default function IconClose({ color = "#ffffff" }) {
  return (
    <svg width="16px" height="16px" viewBox="-0.5 0 25 25" fill="none">
      <path
        d="M3 21.32L21 3.32001"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M3 3.32001L21 21.32"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
