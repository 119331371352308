import { useEffect, useState } from "react";

export default function Message({
  type = "message",
  isInline = false,
  message,
  details,
}) {
  return (
    message && (
      <div
        className={`message flex flex-direction-column flex-gap-xs border-curved ${
          isInline === false
            ? "fixed width-fit-content"
            : "text-center border-none"
        } ${type}`}
      >
        <div className="message-main">{message}</div>
        {details && <div className="message-details">{details}</div>}
      </div>
    )
  );
}
