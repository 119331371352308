import { useEffect, useState } from "react";
import { usePageState } from "../../../Layout";
import {
  apiOnlineMenuUrl,
  executeRequest,
  getApplicationToken,
} from "../../../functions/requests";
import Spacer from "../../../components/Spacer";
import List from "../../../components/List";
import Popup from "../../../components/Popup";
import Form from "../../../components/Form";
import Button from "../../../components/Button";
import IconEdit from "../../../icons/IconEdit";
import IconPlus from "../../../icons/IconPlus";
import IconLoadingWave from "../../../icons/IconLoadingWave";
import { useNavigate } from "react-router-dom";

export default function OnlineMenu() {
  const { pageState, setPageState } = usePageState();
  const navigate = useNavigate();

  const [userData, setUserData] = useState(
    pageState.onlineMenuUserData || null
  );
  const [userDataLoading, setUserDataLoading] = useState(true);

  const getApplicationData = async () => {
    console.log(getApplicationToken("online_menu"));
    // Get connected user data
    await executeRequest(
      apiOnlineMenuUrl + "users/current",
      "GET",
      null,
      {
        Authorization: "Bearer " + getApplicationToken("online_menu"),
      },
      async (data) => {
        setUserData(data);
      },
      (error) => {
        console.log(error);
      }
    );
    setUserDataLoading(false);
  };

  useEffect(() => {
    getApplicationData();
  }, []);

  return (
    <div>
      <h1 className="page-title">Online Menu</h1>
      {userDataLoading ? (
        <IconLoadingWave />
      ) : (
        userData && (
          <>
            <h2>My credentials</h2>
            <Spacer height="20px" />
            <table>
              <thead>
                <tr>
                  <th>First name</th>
                  <th>Last name</th>
                  <th>Permissions</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>{userData.first_name}</td>
                  <td>{userData.last_name}</td>
                  <td>
                    {userData.permissions ? (
                      userData.permissions.map((permission, index) => {
                        return <span key={index}>{permission}</span>;
                      })
                    ) : (
                      <span>All</span>
                    )}
                  </td>
                </tr>
              </tbody>
            </table>
            <Spacer height="20px" />
            {/* Menus section */}
            <Button
              text="View Menu Setups"
              onClicked={() => {
                navigate("/projects/online-menu/menu-settings");
              }}
            />
          </>
        )
      )}
    </div>
  );
}
