export default function IconArrowLeft({ color = "black" }) {
  return (
    <svg
      width="24px"
      height="24px"
      viewBox="0 0 24 24"
      fill="none"
      className="icon-arrow-left"
    >
      <path
        d="M15 7L10 12L15 17"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
