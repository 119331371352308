const apiUrl = "https://portal.edge-solutions.online/api/";
const apiOnlineMenuUrl = "https://menu.edge-solutions.online/api/";
const clientOnlineMenuUrl = "https://menu.edge-solutions.online";
const apiBookingUrl = "https://booking.edge-solutions.online/api/";
const apiRealEstateUrl = "https://realestate.edge-solutions.online/api/";
const apiLoyaltyProgramUrl = "https://loyalty.edge-solutions.online/api/";

const getToken = () => {
  return localStorage.getItem("project_management_platform_token") || null;
};

const getApplicationToken = (applicationSlug) => {
  return (
    localStorage.getItem(
      "project_management_platform_token_" + applicationSlug
    ) || null
  );
};

const saveToken = (token) => {
  localStorage.setItem("project_management_platform_token", token);
  return true;
};

const saveApplicationToken = (applicationSlug, token) => {
  localStorage.setItem(
    "project_management_platform_token_" + applicationSlug,
    token
  );
  return true;
};

const deleteToken = () => {
  localStorage.removeItem("project_management_platform_token");
  return true;
};

const deleteApplicationToken = (applicationSlug) => {
  localStorage.removeItem(
    "project_management_platform_token_" + applicationSlug
  );
  return true;
};

const executeRequest = async (
  url,
  method,
  data,
  headers = {},
  onSuccess,
  onError
) => {
  try {
    const options = {
      method: method,
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        ...headers,
      },
    };

    if (method !== "GET" && data) {
      options.body = JSON.stringify(data);
    }

    const response = await fetch(url, options);

    if (!response.ok) {
      const errorText = await response.text();
      try {
        const errorData = JSON.parse(errorText);
        return onError(errorData.message || "Request failed");
      } catch {
        return onError("Request failed");
      }
    }

    const text = await response.text();
    const responseData = text ? JSON.parse(text) : {};
    onSuccess(responseData);
  } catch (e) {
    onError(e.message || "Network error");
  }
};

export {
  apiUrl,
  apiOnlineMenuUrl,
  clientOnlineMenuUrl,
  apiBookingUrl,
  apiRealEstateUrl,
  apiLoyaltyProgramUrl,
  getToken,
  getApplicationToken,
  saveToken,
  saveApplicationToken,
  deleteToken,
  deleteApplicationToken,
  executeRequest,
};
