import IconLoadingWave from "../icons/IconLoadingWave";

export default function Button({
  icon,
  text,
  onClicked,
  loading = false,
  disabled = false,
  active = false,
}) {
  return (
    <button
      className={`${loading === true ? "loading" : ""} ${
        disabled === true ? "disabled" : ""
      } ${active === true ? "active" : ""}`}
      onClick={onClicked}
    >
      {icon && icon}
      {text}
      {loading && <IconLoadingWave color="white" />}
    </button>
  );
}
