import Layout, { PageStateProvider } from "./Layout";
import { useEffect, useState } from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  useLocation,
} from "react-router-dom";
import Login from "./sections/authentication/Login";
import Dashboard from "./sections/Dashboard";
import Profile from "./sections/profile/Profile";
import Users from "./sections/users/Users";
import SocialMediaManagement from "./sections/social_media_management/SocialMediaManagement";
import SupportTickets from "./sections/support_tickets/SupportTickets";
import WebScraper from "./sections/web_scraper/WebScraper";
import EmailService from "./sections/projects/email_service/EmailService";
import OnlineMenu from "./sections/projects/online_menu/OnlineMenu";
import LoyaltyProgram from "./sections/projects/loyalty_program/LoyaltyProgram";
import Documentation from "./sections/documentation/Documentation";
import Eshops from "./sections/projects/eshops/Eshops";
import Projects from "./sections/projects/Projects";
import Calculator from "./sections/calculator/Calculator";
import RealEstateAgenda from "./sections/projects/real_estate_agenda/RealEstateAgenda";
import Booking from "./sections/projects/booking/Booking";
import Tickets from "./sections/projects/tickets/Tickets";
import OnlineMenuSettings from "./sections/projects/online_menu/OnlineMenuSettings";
import OnlineMenuSetting from "./sections/projects/online_menu/OnlineMenuSetting";
import OnlineMenuOrders from "./sections/projects/online_menu/OnlineMenuOrders";

// Custom component to track pathname inside Router
function AppContent() {
  const location = useLocation();
  const [pathname, setPathname] = useState("");

  useEffect(() => {
    setPathname(location.pathname);
  }, [location]);

  return !pathname.includes("login") ? (
    <Layout>
      <Routes>
        <Route path="/" element={<Dashboard />} />
        <Route path="/web-scraper" element={<WebScraper />} />
        <Route path="/email-service" element={<EmailService />} />
        <Route path="/calculator" element={<Calculator />} />
        <Route
          path="/social-media-management"
          element={<SocialMediaManagement />}
        />
        <Route path="/support-tickets">
          <Route index element={<SupportTickets />} />
          <Route path="completed" element={<SupportTickets />} />
          <Route path="in-progress" element={<SupportTickets />} />
          <Route path="cancelled" element={<SupportTickets />} />
        </Route>
        <Route path="/projects">
          <Route index element={<Projects />} />
          <Route path="online-menu">
            <Route index element={<OnlineMenu />} />
            <Route path="menu-settings">
              <Route index element={<OnlineMenuSettings />} />
              <Route path=":uuid">
                <Route index element={<OnlineMenuSetting />} />
                <Route path="orders">
                  <Route
                    index
                    element={<OnlineMenuOrders showInProgress={true} />}
                  />
                  <Route
                    path="accepted"
                    element={<OnlineMenuOrders showAccepted={true} />}
                  />
                  <Route
                    path="rejected"
                    element={<OnlineMenuOrders showRejected={true} />}
                  />
                </Route>
              </Route>
            </Route>
          </Route>
          <Route path="loyalty-program" element={<LoyaltyProgram />} />
          <Route path="booking" element={<Booking />} />
          <Route path="real-estate-agenda" element={<RealEstateAgenda />} />
          <Route path="tickets" element={<Tickets />} />
          <Route path="eshops" element={<Eshops />} />
        </Route>
        <Route path="/users" element={<Users />} />
        <Route path="/documentation" element={<Documentation />} />
        <Route path="/profile" element={<Profile />} />
      </Routes>
    </Layout>
  ) : (
    <Routes>
      <Route path="/login" element={<Login />} />
    </Routes>
  );
}

function App() {
  return (
    <Router>
      <PageStateProvider>
        <AppContent />
      </PageStateProvider>
    </Router>
  );
}

export default App;
